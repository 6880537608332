import type { GDSStyleObject } from '@leagueplatform/genesis-core';

export const CTA_FOOTER_HEIGHT = '98px';
export const ACCOUNT_SETUP_CONTENT_MAX_WIDTH = '708px';
export const CONTENT_MAX_WIDTH = 952;
export const BACK_BUTTON_WIDTH = 125;
export const PAGE_NAVIGATION_WIDTH = 1178;
export const PAGE_NAVIGATION_HEIGHT = 8 * 7; // 56px – 7 lines on the Genesis 8px grid
export const HERO_BANNER_HEIGHT = 184;
export const ELEMENT_IDS = {
  PAGE_HEADER: 'page-header',
};

export const FORM_LEGEND_STYLE = {
  '.GDS-fieldset-legend': {
    typography: '$headingFour',
    marginBlockEnd: '$one',
    color: '$onSurfaceTextPrimary',
  },
};

export const SDK_CSS_OVERRIDES: GDSStyleObject = {
  '.GDS-elevated-card': {
    boxShadow: '$dropdown',
    border: 'none',
    borderColor: 'transparent !important',
    '.GDS-card-click-area': {
      border: 'none',
      position: 'relative',
      '&:hover': {
        '&:after': {
          content: '',
          width: '100%',
          height: '100%',
          position: 'absolute',
          top: 0,
          left: 0,
          border: '$borderWidths$card solid $colors$interactiveFocusOuter',
          borderRadius: '$medium',
        },
      },
    },
  },
  '.health-activity-layout': {
    backgroundColor: '$surfaceBackgroundPrimary',
  },
  '.health-activity-content': {
    backgroundColor: '$surfaceCardSecondary',
    boxShadow: '$dropdown',
  },
  '.toolbox-richtext': {
    backgroundColor: '$surfaceCardSecondary !important',
  },
  '.toolbox-option-wrapper': {
    border:
      '$borderWidths$card solid $colors$onSurfaceBorderSubdued !important',
  },
  '.toolbox-badge': {
    backgroundColor: '$surfaceBackgroundSecondary',
    borderRadius: '$medium',
    '& > span': {
      typography: '$overline',
    },
  },
};
